.gdpr-cookie-consent {
 background: $white;
 border: 1px solid $border-color;
 bottom: $grid-gutter-width/2;
 display: none;
 font-size: px2rem(14);
 left: $grid-gutter-width/2;
 max-width: 500px;
 padding: $grid-gutter-width/2;
 position: fixed;
 right: $grid-gutter-width/2;
 z-index: 9999;

 a {
 text-decoration: underline; }

 &__text {
 margin-bottom: $grid-gutter-width/2; } }
