// List Groups

// scss-docs-start list-group-mixin
@mixin list-group-item-variant($state, $background, $color) {
 .list-group-item-#{$state} {
 color: $color;
 background-color: $background;

 &.list-group-item-action {
 &:hover,
 &:focus {
 color: $color;
 background-color: shade-color($background, 10%);
 }

 &.active {
 color: $white;
 background-color: $color;
 border-color: $color;
 }
 }
 }
}
// scss-docs-end list-group-mixin
